import React, { CSSProperties } from "react";
import "./divider.scss";

interface IProps {
  /**Padding around the divider. Default is padding: 1rem 0 */
  padding?: string;
  /**Colour of the divider. Default is $baseWhite */
  colour?: string;
}

export default function Divider(props: IProps) {
  const { padding, colour } = props;
  return (
    <div className="divider-main" style={{ padding: padding }}>
      <div className="divider-inner" style={{ borderColor: colour }}></div>
    </div>
  );
}
