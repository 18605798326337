import React from "react";
import "./footer.scss";
import FooterLinkGroup, { TFooterLink } from "./footerLinkGroup/FooterLinkGroup";
import Divider from "../divider/Divider";
import { IconDefinition, faFacebook, faInstagram, faTiktok, faXTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const SOCIALS: Array<IconDefinition> = [faFacebook, faInstagram, faXTwitter, faTiktok];

const COMPANY_FOOTER_LINKS: Array<TFooterLink> = [
  {
    label: "Contact Us",
    url: "/contact-us",
  },
  // {
  //   label: "About Us",
  //   url: "/about-us",
  // },
];

const SERVICES_FOOTER_LINKS: Array<TFooterLink> = [
  {
    label: "Laundry",
  },
  {
    label: "Dry Cleaning",
  },
  {
    label: "Wash and Fold Service",
  },
];

const SERVING_FOOTER_LINKS: Array<TFooterLink> = [
  {
    label: "Cambridge",
  },
];

const SERVING_SOON_FOOTER_LINKS: Array<TFooterLink> = [
  {
    label: "Ayr",
  },
  {
    label: "Guelph",
  },
  {
    label: "Kitchener",
  },
  {
    label: "Waterloo",
  },
  {
    label: "Woodstock",
  },
];

export default function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-parent-container">
        <FooterLinkGroup title="Company" links={COMPANY_FOOTER_LINKS} />
        <FooterLinkGroup title="Services" links={SERVICES_FOOTER_LINKS} />
        <FooterLinkGroup title="Now Serving" links={SERVING_FOOTER_LINKS} />
        <FooterLinkGroup title="Serving soon..." links={SERVING_SOON_FOOTER_LINKS} />
      </div>
      <Divider />
      <div className="footer-socials-container">
        {SOCIALS?.map((social, index) => (
          <FontAwesomeIcon key={index} className="footer-socials-icon" icon={social} size="2x" />
        ))}
      </div>
      <p className="footer-copyright">Copyright © King Coin Laundry {new Date().getFullYear()}</p>
    </footer>
  );
}
