import React from "react";
import { Outlet } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

export default function Main() {
  return (
    <React.Fragment>
      <Header />

      {/*Render views as set in App.tsx */}
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}
