import React from "react";
import "./footerGroupLink.scss";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

export type TFooterLink = {
  label: string;
  url?: string;
};

interface IProps {
  title: string;
  links: Array<TFooterLink>;
}

export default function FooterLinkGroup(props: IProps) {
  const { title, links } = props;
  const navigate = useNavigate();
  return (
    <div className="footer-link-group-container">
      <p className="footer-link-title footer-text-colour">{title}</p>
      {links?.map((link, index) => (
        <p
          key={index}
          onClick={link?.url ? () => navigate(link?.url as string) : undefined}
          className={classNames("footer-link-label footer-text-colour", { "footer-link-label-url": !!link.url })}>
          {link.label}
        </p>
      ))}
    </div>
  );
}
