import React, { Suspense, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./_global.scss";
import Main from "./Main";
import Pricing from "./views/pricing/Pricing";
import GiftCards from "./views/giftCards/GiftCards";
import ContactUs from "./views/contactUs/ContactUs";

const Home = lazy(() => import("./views/home/Home"));

const SuspenseWrapper = ({ fallback, children }: { fallback?: React.ReactNode; children: React.ReactNode }) => (
  <Suspense fallback={fallback ?? <div>Loading...</div>}>{children}</Suspense>
);

//This has been removed from the package.json scripts
// "build": "react-scripts build",

function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            <Route
              index
              element={
                <SuspenseWrapper>
                  <Home />
                </SuspenseWrapper>
              }
            />
            {/* Temporary */}
            <Route
              path="/pricing"
              element={
                <SuspenseWrapper>
                  <ContactUs />
                </SuspenseWrapper>
              }
            />
            {/* <Route path="/gift-cards" element={<GiftCards />} /> */}

            {/* Temporary */}
            <Route
              path="/schedule-pickup"
              element={
                <SuspenseWrapper>
                  <ContactUs />
                </SuspenseWrapper>
              }
            />

            <Route
              path="/contact-us"
              element={
                <SuspenseWrapper>
                  <ContactUs />
                </SuspenseWrapper>
              }
            />

            {/* Default route to redirect to */}
            <Route
              path="*"
              element={
                <SuspenseWrapper>
                  <Home />
                </SuspenseWrapper>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
