import React, { useState } from "react";
import "./header.scss";
import { NavLink, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faSquarePhone, faXmark } from "@fortawesome/free-solid-svg-icons";
import Button from "../button/Button";
import { useWindowSize } from "../../hooks/useWindowSize";
import { MOBILE_WIDTH } from "../../helpers/ScreenSizes";
import classNames from "classnames";
import Divider from "../divider/Divider";
import { SOCIALS } from "../footer/Footer";

type TMenuItem = {
  label: string;
  url: string;
};

interface IMobileMenu {
  open: boolean;
}

const MENU_ITEMS: Array<TMenuItem> = [
  { label: "Business", url: "/" },
  { label: "Pricing", url: "/pricing" },
  // { label: "Gift Cards", url: "/gift-cards" },
];

export default function Header(props: any) {
  const navigate = useNavigate();
  const windowSize = useWindowSize();
  const [mobileMenu, setMobileMenu] = useState<IMobileMenu>({
    open: false,
  });

  function onNavigateClick(url: string) {
    setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }));
    navigate(url);
  }

  return (
    <header className="header">
      <div className="outer-container">
        <div className="image-logo-container">
          <img src="/images/logo_image.jpg" alt="King coin laundry logo" className="image-logo" />
        </div>
        {/* Mobile Menu */}
        {windowSize.width <= MOBILE_WIDTH ? (
          <div className="header-mobile-menu">
            <FontAwesomeIcon
              onClick={() => setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }))}
              icon={faBars}
              size="2x"
              className="header-mobile-menu-icon"
            />
            <div
              onClick={() => setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }))}
              className={classNames("mobile-menu-backdrop", { "mobile-menu-backdrop-visible": mobileMenu.open })}></div>
            <div
              className={"mobile-menu-container"}
              style={{
                width: mobileMenu.open ? "80%" : undefined,
                boxShadow: mobileMenu.open ? "rgba(0, 0, 0, 0.56) 0px 22px 70px 4px" : undefined,
              }}>
              <div
                className={classNames("mobile-menu-inner-container", {
                  "mobile-menu-inner-container-hidden": !mobileMenu.open,
                })}>
                <div>
                  <div className="mobile-menu-header-container">
                    <div className="image-logo-container">
                      <img src="/images/logo_image.jpg" alt="King coin laundry logo" className="image-logo" />
                    </div>
                    <FontAwesomeIcon
                      icon={faXmark}
                      className="mobile-menu-close-button"
                      onClick={() => setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }))}
                    />
                  </div>
                  <div className="mobile-menu-options-container">
                    {MENU_ITEMS?.map((item, index) => (
                      <NavLink
                        key={index}
                        to={item.url}
                        onClick={() => setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }))}
                        className={({ isActive, isPending, isTransitioning }) =>
                          [isActive ? "menu-item-active" : "", "menu-item"].join(" ")
                        }>
                        {item?.label}
                      </NavLink>
                    ))}
                    <a href="tel:519-650-5112" className="phone-number">
                      <FontAwesomeIcon className="phone-icon" size="1x" icon={faSquarePhone} /> {"(519) 650-5112"}
                    </a>
                  </div>
                  <Divider padding="15px 10px" colour="#F2F4F7" />
                  <div className="mobile-menu-options-container">
                    <p className="option-header">Company</p>
                    <NavLink
                      onClick={() => setMobileMenu(prevState => ({ ...prevState, open: !prevState.open }))}
                      to={"/contact-us"}
                      className={({ isActive }) => [isActive ? "menu-item-active" : "", "menu-item"].join(" ")}>
                      Contact Us
                    </NavLink>
                  </div>
                </div>
                <div className="mobile-menu-footer-container">
                  <div className="mobile-menu-socials-container">
                    {SOCIALS?.map((social, index) => (
                      <FontAwesomeIcon key={index} icon={social} className="socials-icon" />
                    ))}
                  </div>
                  <Button type="primary" block onClick={() => onNavigateClick("/schedule-pickup")} size="small">
                    Schedule Pickup
                  </Button>
                  <p className="mobile-menu-copyright">Copyright © King Coin Laundry {new Date().getFullYear()}</p>
                </div>
              </div>
            </div>
          </div>
        ) : (
          // Desktop Menu
          <div className="options-menu-container">
            {MENU_ITEMS?.map((item, index) => (
              <NavLink
                key={index}
                to={item.url}
                className={({ isActive, isPending, isTransitioning }) =>
                  [isActive ? "menu-item-active" : "", "menu-item"].join(" ")
                }>
                {item?.label}
              </NavLink>
            ))}
            <Button type="primary" onClick={() => navigate("/schedule-pickup")} size="medium">
              Schedule Pickup
            </Button>
            <a href="tel:519-650-5112" className="phone-number">
              <FontAwesomeIcon className="phone-icon" size="1x" icon={faSquarePhone} /> {"(519) 650-5112"}
            </a>
          </div>
        )}
      </div>
    </header>
  );
}
