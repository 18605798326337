import React, { useEffect } from "react";
import "./contactUs.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faLocationDot, faMobile, faSquarePhone } from "@fortawesome/free-solid-svg-icons";

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contact-us-page">
      <div className="contact-us-main-container">
        <div className="details-container">
          <h1>Contact Us</h1>
          <p className="contact-us-subtitle">Contact us for pricing, pick up and delivery inquires and more!</p>
          <a className="contact-link" href="tel:519-650-5112">
            <FontAwesomeIcon className="phone-icon" size="1x" icon={faSquarePhone} /> {"(519) 650-5112"}
          </a>
          <a className="contact-link" href="mailto:kingcoinlaundry51@gmail.com">
            <FontAwesomeIcon className="phone-icon" size="1x" icon={faEnvelope} /> {"kingcoinlaundry51@gmail.com"}
          </a>
          <a className="contact-link" rel="noreferrer" target="_blank" href="https://maps.app.goo.gl/NF7Rtg11kPHjnR6K9">
            <FontAwesomeIcon className="phone-icon" size="1x" icon={faLocationDot} />{" "}
            {"1453 King St E, Cambridge, ON N3H 3R3"}
          </a>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2899.3545389407473!2d-80.35012912431475!3d43.39051856938156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b89e65cf9f5e1%3A0xe6a4ab65ffb41706!2sKing%20Coin%20Laundry%20%26%20Laundromat%20Cambridge%20Preston!5e0!3m2!1sen!2sca!4v1713125379636!5m2!1sen!2sca"
            width="600"
            height="450"
            className="contact-us-maps"
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
}
