import React, { ReactNode } from "react";
import "./button.scss";
import classNames from "classnames";

interface IProps {
  type?: "primary" | "secondary";
  onClick: () => void;
  disabled?: boolean;
  htmlType?: "button" | "reset" | "submit";
  size?: "small" | "medium" | "large";
  block?: boolean;
  children?: ReactNode;
}

const CLASS_PREFIX = "main-button";

export default function Button(props: IProps) {
  const {
    type = "primary",
    onClick,
    disabled = false,
    htmlType = "button",
    size = "medium",
    block = false,
    children,
  } = props;

  const buttonClassNames = classNames("main-button", {
    [`${CLASS_PREFIX}-${type}`]: type,
    [`${CLASS_PREFIX}-${size}`]: size,
    [`${CLASS_PREFIX}-block`]: block,
    [`${CLASS_PREFIX}-${type}-disabled`]: disabled,
  });

  return (
    <button className={buttonClassNames} onClick={onClick} disabled={disabled} type={htmlType}>
      {children}
    </button>
  );
}
